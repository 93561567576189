var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mt-4"},[_c('v-col',[_c('v-card',{attrs:{"loading":_vm.loadingGet || _vm.loadingRoles}},[_c('v-toolbar',{attrs:{"color":"info","flat":"","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text font-weight-black"},[_vm._v(" # "+_vm._s(_vm.user.id || '--')+" ")])],1),_c('v-divider'),_c('validation-observer',{ref:"observer"},[_c('v-form',{staticClass:"mt-4"},[_c('v-container',{},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":_vm.loadingGet,"hide-details":"auto","label":"Nome","error-messages":errors},model:{value:(_vm.newUser.name),callback:function ($$v) {_vm.$set(_vm.newUser, "name", $$v)},expression:"newUser.name"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"username"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":_vm.loadingGet,"hide-details":"auto","label":"Usuário","error-messages":errors},model:{value:(_vm.newUser.username),callback:function ($$v) {_vm.$set(_vm.newUser, "username", $$v)},expression:"newUser.username"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":_vm.loadingGet,"hide-details":"auto","label":"E-mail","error-messages":errors},model:{value:(_vm.newUser.email),callback:function ($$v) {_vm.$set(_vm.newUser, "email", $$v)},expression:"newUser.email"}})]}}])})],1),(!_vm.edit && !_vm.loadingGet)?_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":_vm.loadingGet,"hide-details":"auto","label":"Senha","type":"password","error-messages":errors},model:{value:(_vm.newUser.password),callback:function ($$v) {_vm.$set(_vm.newUser, "password", $$v)},expression:"newUser.password"}})]}}],null,false,1754339637)})],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"customerId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.customers,"item-text":"companyName","item-value":"id","label":"Cliente","clearable":"","hide-details":"auto","loading":_vm.loadingCustomers,"disabled":_vm.loadingCustomers,"error-messages":errors},model:{value:(_vm.newUser.customerId),callback:function ($$v) {_vm.$set(_vm.newUser, "customerId", $$v)},expression:"newUser.customerId"}})]}}])})],1)],1),(!_vm.newUser.customerId)?_c('v-row',{staticClass:"my-2"},_vm._l((_vm.roles),function(role){return _c('v-col',{key:role.id,attrs:{"cols":"2"}},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"label":role.description,"value":role.id,"color":"info","hide-details":"auto","multiple":""},model:{value:(_vm.newUser.roles),callback:function ($$v) {_vm.$set(_vm.newUser, "roles", $$v)},expression:"newUser.roles"}})],1)}),1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-switch',{staticClass:"mt-0 pt-0",attrs:{"color":"info","disabled":_vm.loadingGet,"true-value":true,"false-value":false,"label":"Ativo","hide-details":"auto"},model:{value:(_vm.newUser.active),callback:function ($$v) {_vm.$set(_vm.newUser, "active", $$v)},expression:"newUser.active"}})],1)],1)],1)],1)],1),_c('v-divider',{staticClass:"mt-4"}),_c('v-card-actions',[_c('v-row',[_c('v-col',{staticClass:"text-left",attrs:{"cols":"12"}},[_c('btn-save',{attrs:{"loading":_vm.loadingSave},on:{"click":_vm.save}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }