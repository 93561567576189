<template>
  <v-row class="mt-4">
    <v-col>
      <v-card
        :loading="loadingGet || loadingRoles"
      >
        <v-toolbar
          color="info"
          flat
          dense
        >
          <v-toolbar-title class="white--text font-weight-black">
            # {{ user.id || '--' }}
          </v-toolbar-title>
        </v-toolbar>
        <v-divider />
        <validation-observer ref="observer">
          <v-form class="mt-4">
            <v-container class="">
              <v-row>
                <v-col
                  cols="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    vid="name"
                  >
                    <v-text-field
                      v-model="newUser.name"
                      :disabled="loadingGet"
                      hide-details="auto"
                      label="Nome"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  cols="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    vid="username"
                  >
                    <v-text-field
                      v-model="newUser.username"
                      :disabled="loadingGet"
                      hide-details="auto"
                      label="Usuário"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    vid="email"
                  >
                    <v-text-field
                      v-model="newUser.email"
                      :disabled="loadingGet"
                      hide-details="auto"
                      label="E-mail"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  v-if="!edit && !loadingGet"
                  cols="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    vid="password"
                  >
                    <v-text-field
                      v-model="newUser.password"
                      :disabled="loadingGet"
                      hide-details="auto"
                      label="Senha"
                      type="password"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    vid="customerId"
                  >
                    <v-select
                      v-model="newUser.customerId"
                      :items="customers"
                      item-text="companyName"
                      item-value="id"
                      label="Cliente"
                      clearable
                      hide-details="auto"
                      :loading="loadingCustomers"
                      :disabled="loadingCustomers"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row class="my-2" v-if="!newUser.customerId">
                <v-col
                  v-for="(role) in roles"
                  :key="role.id"
                  cols="2"
                >
                  <v-checkbox
                    v-model="newUser.roles"
                    :label="role.description"
                    :value="role.id"
                    color="info"
                    hide-details="auto"
                    class="mt-0"
                    multiple
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="2">
                  <v-switch
                    v-model="newUser.active"
                    color="info"
                    class="mt-0 pt-0"
                    :disabled="loadingGet"
                    :true-value="true"
                    :false-value="false"
                    label="Ativo"
                    hide-details="auto"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </validation-observer>
        <v-divider class="mt-4" />
        <v-card-actions>
          <v-row>
            <v-col
              cols="12"
              class="text-left"
            >
              <btn-save
                :loading="loadingSave"
                @click="save"
              />
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import _ from 'lodash'
  import rolesApi from '@/api/roles'
  import customersApi from '@/api/customers'

  export default {

    props: {
      formValidation: {
        type: Object,
        default: () => { return {} },
      },
      loadingSave: {
        type: Boolean,
        default: false,
      },
      loadingGet: {
        type: Boolean,
        default: false,
      },
      user: {
        type: Object,
        default: () => { return {} },
      },
    },

    data () {
      return {
        roles: [],
        customers: [],
        loadingRoles: false,
        loadingCustomers: false,
        newUser: {
          name: null,
          username: null,
          email: null,
          password: null,
          active: true,
          roles: [],
        },
      }
    },

    computed: {
      edit () {
        return !_.isEmpty(this.user)
      },
    },

    watch: {
      formValidation (val) {
        if (_.isEmpty(val)) return false

        return this.$refs.observer.setErrors(val)
      },

      user (val) {
        if (_.isEmpty(val)) return

        val.roles = _.map(val.roles, 'id')

        this.newUser = val
      },
    },

    mounted () {
      this.loadRoles()
      this.loadCustomers()
    },

    methods: {
      save () {
        this.$refs.observer.reset()
        this.$emit('save', this.newUser)
      },

      async loadRoles () {
        try {
          this.loadingRoles = true

          const response = await rolesApi.list()

          this.roles = response.data.roles
        } catch (e) {
          console.log(e)
        } finally {
          this.loadingRoles = false
        }
      },

      async loadCustomers () {
        try {
          this.loadingCustomers = true

          const response = await customersApi.list()

          this.customers = response.data.customers
        } catch (e) {
          console.log(e)
        } finally {
          this.loadingCustomers = false
        }
      },
    },

  }
</script>
